var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.isLoading}},[(_vm.clusterId)?_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',_vm._b({attrs:{"title":"B.B Hesap Hareketleri","icon":"mdi mdi-home-switch-outline","edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query},on:{"clear-filters":function($event){return _vm.clearFilters()},"click:edit":_vm.handleEditClick,"reload":_vm.loadList,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)}}},'rs-table-title-bar',_vm.titleBarAttrs,false),[_c('template',{slot:"buttons:prepend"},[_c('rs-select',{staticClass:"float-end me-2",staticStyle:{"max-width":"150px"},attrs:{"label":"BB Filtrele","items":_vm.debtStatusList,"dense":""},on:{"change":_vm.handleFilterChange},model:{value:(_vm.filtertype),callback:function ($$v) {_vm.filtertype=$$v},expression:"filtertype"}}),_c('rs-select',{staticClass:"float-end me-2",staticStyle:{"max-width":"150px"},attrs:{"label":"Tarih Filtrele","items":_vm.dateFilterList,"dense":""},on:{"change":_vm.handleDateFilterChange},model:{value:(_vm.filterdate),callback:function ($$v) {_vm.filterdate=$$v},expression:"filterdate"}})],1)],2):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[(
                _vm.can('print') &&
                _vm.selectedItems.length === 1 &&
                _vm.selectedItems[0].type === 'collecting'
              )?_c('v-btn',{staticClass:"me-2",attrs:{"color":"pink","outlined":"","small":""},on:{"click":function($event){return _vm.$refs.collectingReceiptForm.show()}}},[_vm._v(" Tahsilat Fişi Yazdır ")]):_vm._e(),(
                _vm.can('edit-house-income') &&
                _vm.selectedItems.length === 1 &&
                _vm.selectedItems[0].type === 'collecting'
              )?_c('v-btn',{attrs:{"color":"pink","outlined":"","dark":"","small":""},on:{"click":function($event){return _vm.$refs.collectingReceiptNoForm.show()}}},[_vm._v(" Makbuz Seri No Değiştir ")]):_vm._e()],1)],1)]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"body.append",fn:function({ headers }){return [_c('rs-table-foot-totals',{attrs:{"headers":headers,"totals":_vm.footTotals}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'incomes.houses.show',
            params: { id: item.house_id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.door_number",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{
            name: 'definitions.houses.edit',
            params: { id: item.house_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.balance_no",fn:function({ item, value }){return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleBBEditClick(item)}}},[_c('rs-table-cell-string',{attrs:{"value":value}})],1)]}},{key:"item.resident_balance",fn:function({ value }){return [_c('rs-table-cell-balance',{attrs:{"colored":"","value":value}})]}},{key:"item.house_balance",fn:function({ value }){return [_c('rs-table-cell-balance',{attrs:{"colored":"","value":value}})]}},{key:"item.payed_on",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.resident_name",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{
            name: 'definitions.residents.edit',
            params: { id: item.resident_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.last_payment_on",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.action_on",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.debt_amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.deferment_amount",fn:function({ value, item }){return [(!item.collecting_id)?_c('rs-table-cell-number',{attrs:{"muted-zeros":"","price":"","value":value}}):_vm._e()]}},{key:"item.debt_total_amount",fn:function({ value, item }){return [(!item.collecting_id)?_c('rs-table-cell-number',{attrs:{"price":"","value":value}}):_vm._e()]}},{key:"item.collecting_amount",fn:function({ item, value }){return [(item.collecting_id)?_c('rs-table-cell-number',{attrs:{"price":"","value":value}}):_vm._e()]}},{key:"item.is_transferred",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.description",fn:function({ value }){return [_c('rs-table-cell-string',{attrs:{"limit":"20","value":value}})]}}],null,false,3355298568),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1):_c('v-card-text',[_vm._v(" Listeyi görmek için üst menüden bir toplu yaşam alanı seçin. ")]),_c('CollectingDetail',{ref:"collectingDetailForm"}),_c('CollectingReceiptNoForm',{ref:"collectingReceiptNoForm",attrs:{"house":_vm.house,"selected":_vm.selectedItems},on:{"saved":function($event){return _vm.loadList()}}}),_c('CollectingReceiptForm',{ref:"collectingReceiptForm",attrs:{"house":_vm.house,"selected":_vm.selectedItems}}),_c('DebtForm',{ref:"debtForm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }